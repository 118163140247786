.avatarImage {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .avatardesign{
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
  }
  .pointer{
    cursor: pointer;
  }
  .active{
    color: olivedrab;
  }
  

.pg-red{
  background-color: red !important;
}
.pg-yellow{
  background-color: yellow !important;
}

.pg-green{
  background-color: green !important;
}